.container {
    /* 水平居中 */
    width: 100%;
    height: 100vh;
    max-width: 1240px;
    margin: 0 auto;
    padding: 30px 20px 10px;
    /* background-color: rgb(217, 225, 227); */
    display: flex;
    justify-content: center;
}

.loginWithThirdParty {
    background-color: white;
    width: 504px;
    height: 50vh;
    padding: 24px 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column; 
}

.brand-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.loginTittle {
    font-size: 40px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    text-align: center;
    color: rgb(57, 55, 55);
}

.login-box {
    display: flex;
    justify-content: center;
    justify-items:center ;
    /* 垂直居中 */
    align-items: center;
}

.loginButton {
    width: 50px;
}

.contentText {
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px; 
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #23554b, #d2aa1c);
    /* 设置文本的背景为上面定义的渐变 */
    -webkit-background-clip: text;
    background-clip: text;

    /* 将文本颜色设置为透明，这样背景渐变就可以显示出来 */
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: bold;
}

.planBox {
    background: linear-gradient(to right, #BFFEEB, white);
    height: 111px;
    border-radius: 20px;
    padding: 20px; /* Add padding to ensure text is inside the box */
    margin-bottom: 20px; /* Add margin to create 
                        space between this box and anything below */
}

.planText {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 27px;
    font-weight: bold;
    
}

.specialText {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #23554b, #d2aa1c);
    /* 设置文本的背景为上面定义的渐变 */
    -webkit-background-clip: text;
    background-clip: text;

    /* 将文本颜色设置为透明，这样背景渐变就可以显示出来 */
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: bold;
}




.logoutBox {
    display: flex;
    justify-content: center;
}

.logoutButton {
    align-self: center; /* Aligns the button 
                    to the start of the flex container */
    border-radius: 5px;
    cursor: pointer;
    font-size: 14.5px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    background-color: #00D093;
    height: 40px;
    width: 120px;
    color: #fff;
    margin-top: 20px;
}

.little-text-box {
    align-self: center; 
}
.little-text {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    color: gray;
    
}




