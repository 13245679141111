.page-one {
    background: linear-gradient(to right, #BFFEEB, white);
    /* height: 90vh; */
}

a {
    text-decoration: none; /* 这会移除下划线 */
}

.container {
    /* 水平居中 */
    width: 100%;
    max-width: 1240px;
    margin: 100px auto 150px;
    padding: 10px 20px;
    /* background-color: rgb(217, 225, 227); */

}


.area-top {
    display: flex;
    width: 100%;
    /* height: 548px; */
    /* background-color: rgb(231, 191, 191); */
}

.top-box-left {
    max-width: 40%;
    /* height: 548px;  */
    /* background: rgb(176, 176, 219);  */
    margin-right: 80px;
}

.top-box-right {
    max-width: 60%;
    /* height: 548px;  */
    /* background: rgb(133, 213, 207);  */
}



.top-left-title {
    font-size: 46px;
    /* 字要左对齐 */
    font-weight: 600;
    text-align: left;
    font-family: 'Helvetica', 'Arial', sans-serif;
    margin: 0 0 25.2px;

}

.normal-text-box {
    font-size: 18px;
    /* 字要左对齐 */
    text-align: left;
    font-family: 'Helvetica', 'Arial', sans-serif ;
    margin: 0 0 25.2px;
    color: #545c66;
    /* height: 99px; */
}

.button-box-p1 {
    margin: 10px 0 80px;
    display: flex;
    align-items: center;
    /* 元素右对齐 */
    justify-content: flex-end;
    /* background-color: #b7b7bd; */
}

.button-box {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;      /* 使用Flexbox布局 */
    align-items: center; /* 垂直居中子项 */
    /* justify-content: center; */
    /* margin: 10px;
    padding: 0 250px 15px 0; */
}

.button {
    display: flex; /* Enable flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    text-align: center;
    font-size: 17px;
    font-family:  'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    /* margin: 10px; */
    padding: 8px 10px;
    color: white;
    background-color: #00D093;
    border-radius: 20px;
    cursor: pointer;
    border: none; /* Remove border if it exists */
    outline: none; /* Remove focus outline if it exists */
    height: 60px;

}

.button:hover {
    background-color: #008D73; /* Darker shade of green */
    color: #E0FFE0; /* Light green text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Simple shadow for depth */
}

.notAvailable {
    display: flex; /* Enable flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    text-align: center;
    font-size: 14px;
    font-family:  'Helvetica', 'Arial', sans-serif;
    /* margin: 10px; */
    padding: 8px 10px;
    color: white;
    background-color: #76d4b8;
    border-radius: 20px;
    cursor: pointer;
    border: none; /* Remove border if it exists */
    outline: none; /* Remove focus outline if it exists */
    height: 60px;
    margin-right: 40px;

}


.chrome-logo {
    display: block;
    width: 70px; /* Adjust width as necessary */
    /* Adjust vertical margin as necessary to align it properly */
    margin: 0 5px 0 0;
}


.icon {
    margin-left: 20px;
    font-size: 40px; 
    color:#5C64F4
}

.text {
    display: inline-block;
    /* 使文本在同一行上显示 */
    margin-right: 20px;
    /* 可选的右侧间距，以分隔文本 */

}
.textSpecial {
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #23554b, #d2aa1c);

    /* 设置文本的背景为上面定义的渐变 */
    -webkit-background-clip: text;
    background-clip: text;

    /* 将文本颜色设置为透明，这样背景渐变就可以显示出来 */
    -webkit-text-fill-color: transparent;
    color: transparent;
    
    font-weight: bold;
    display: inline-block;
    /* 使文本在同一行上显示 */
    font-size: 54px;
}

.top-right-hero {
    max-width: 100%;
    border-radius: 20px;

}

.blinking-text {
    font-size: 48px;
    font-weight: bold;
}

.blinking-line {
    display: inline-block;
    animation: blink .7s infinite;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.15;
    text-align: center;
}

.color-text {
    color: #274e13;
    padding: 0 0 5px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;

}

.area-bottom{
    display: flex;
    align-items: center;
}

.area-bottom .button {
    margin-right: 40px;
}


.area-rolling {
    /* 这里要不要加 width： 100%  */
    height: 150px;
    padding: 0 20px 25px;
    margin-top: 10px;
    /* height: 150px; */
    padding: 20px 20px 10px;
}

.rolling-box {
    position: relative;
    margin: 0 auto;
}

.companyLogo {
    margin: 50px 0;
    width: 120px;
    box-shadow: 3px 6px 8px #dde0e8;
}


.area-instruct1 {
    display: flex;
    width: 100%;
    /* height: 548px; */
    background-color: rgb(231, 191, 191);
}

.instruction-text-box {
    background:#5C64F4;
    max-width: 40%;
    height: 548px; 
    /* background: rgb(176, 176, 219);  */
    padding-right: 20px;
}

.instruction-video-box {
    max-width: 60%;
    /* height: 548px;  */
    background: rgb(133, 213, 207); 
    padding-right: 20px;

}

.page-two {
    background: linear-gradient(to right, #BFFEEB, white);
    
}

.title-area{
    margin-bottom: 25px;
    
}

.title-text {
    font-size: 48px;
    /* 字要左对齐 */
    font-weight: 600;
    text-align: center;
    font-family: 'Helvetica', 'Arial', sans-serif;
    margin: 0 0 25.2px;

}

.subTitle-text {
    max-width: 100%;
    font-size: 18px;
    /* 字要左对齐 */
    text-align: center;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #039277;
    font-weight: 700;
}

.mainPic-area {
    max-width: 100%;
    padding-top: 20px;
}

.main-pic {
    max-width: 100%;
    border-radius: 25px;
}




.page-three {
    
    background: linear-gradient(to right, #BFFEEB, white);
    
}


.top-box-text {
    max-width: 40%;
    /* height: 548px;  */
    /* background: rgb(176, 176, 219);  */
    margin-left: 0px;
    padding-left: 100px;
    
}


.top-box-pic {
    max-width: 60%;

}




.top-right-texts {
    font-size: 40px;
    /* 字要左对齐 */
    text-align: left;
    font-family: "Open Sans", sans-serif;
    margin: 0 0 40px;

}


.main-content {
    width: 100%;
    /* background-color: #BFFEEB; */
    display: flex;
    justify-content: space-between; /* 分散对齐 */
}


.left-blocks {

}

.single-block {
    height: 220px;
    width: 500px;
    background-color: #d5efe7;
    margin: 30px;
    padding: 20px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
}

.icon-box{

}


.outlined-icon {
    font-size: 40px; 
    color:#35e3af
}

.text-box {
    width: 80%;
} 

.block-title {
    font-size: 25px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
    color: #20262E;
}

.block-content {
    font-family: "Open Sans", sans-serif;
    color: #545c66;
    font-size: 15px;
}