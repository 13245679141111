* {
    box-sizing: border-box;
    
}

.plans-container {
    background: linear-gradient(to right, #BFFEEB, white);
    display: flex;
    min-height: 100vh;
    justify-content: center;
}

.title-text {
    font-size: 48px;
    /* 字要左对齐 */
    font-weight: 600;
    text-align: center;
    font-family: 'Helvetica', 'Arial', sans-serif;
    padding-top: 60px;
}

.plan-items {
    margin-top: 60px;
    position: relative;
    border: 2px solid none;
    border-radius: 10px;
    background-color:#c1f4e4;
    padding: 20px 10px;
    text-align: center;
    min-height: 500px;
    height: fit-content;
    width: 320px;
    flex-shrink: 0;
    margin-right: 50px;
    padding-bottom: 50px;
}

.plan-items:hover {
    border: 2.5px solid #BFFEEB;
}

.plan-name {
    margin-top: 10px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #545c66;
    font-weight: bold;
    font-size: 22px;
}

.plan-desc {
    color: #666;
    font-size: 15px;
    margin-top: 12px;
}

.plan-points {
    font-weight: bold;
    color: #20262E;
    margin-top: 12px;
    font-size: 20px;
}

.plan-price {
    font-family: 'Helvetica', 'Arial', sans-serif ;
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #184d42, #d2aa1c);

    /* 设置文本的背景为上面定义的渐变 */
    -webkit-background-clip: text;
    background-clip: text;

    /* 将文本颜色设置为透明，这样背景渐变就可以显示出来 */
    -webkit-text-fill-color: transparent;
    color: transparent;
    /* color: #0ca88b;; */
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
}

.plan-hot {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 24px;
    color: #ff3c3c;
}


.button {

    width: 180px;
    text-align: center;
    font-size: 18px;
    font-family:  'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    color: white;
    background-color: #00D093;
    border-radius: 20px;
    cursor: pointer;
    border: none; /* Remove border if it exists */
    outline: none; /* Remove focus outline if it exists */
    height: 60px;

}

.icon {
    margin-right: 10px;
    font-size: 20px;
}

.plan-descBox {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px;
    color:#079168;
}


.footer {
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    left: 0;
}

